import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SectionHeading} from '../../../components/Common/common.style';

export const TestimonialWrapper = styled.section`

`;

export const SectionWrapper = styled.div`
    display:flex;
    align-items:center;
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const Heading = styled(SectionHeading)`
    text-align: center;
`;

export const SliderOuterWrapper = styled.div`
    max-width:800px;
    margin:0px auto;
    position:relative;

    .slick-list{
        line-height:0;
    }
`;

export const SliderWrapper = styled.div`
    outline:0;
`;

export const TestimonialCard = styled.div`
    background: #fff;
    box-shadow: 0px 0px 8px 1px #ddd;
    margin:40px 25px;
    text-align:center;

    @media ${device.mobileXL} {
        margin:40px 15px 20px;
    }
`;

export const ImageLayout = styled.div`
    width:80px;
    flex-shrink:0;
    border-radius:100%;
    position: relative;
    top: -40px;
    margin: 0px auto;

    .authorImg{
        width:80px;
        height:80px;
        border-radius:100%;
        object-fit:cover;
    }

`;

export const TextLayout = styled.div`
    margin-top: -20px;
    padding: 0px 40px 40px;

    h5{
        margin-bottom:10px;
        line-height:1;

        @media ${device.tablet} {
            padding:0px;
        }
    }

    p{
        line-height:1;
        margin-bottom:20px;

        @media ${device.tablet} {
            padding:0px;
        }
    }

    span{
        margin-bottom:0px;
        display:block;
        font-size:14px;
        line-height:24px;
    }
`;


export const NextPrev = styled.div`
    text-align:center;
`;

export const Button = styled.button`
    border:none;
    background:none;
    outline:none;
    line-height:1;

    :focus{
        outline:0;
    }
`;

export const PrevImg = styled.img`
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

export const NextImg = styled.img`
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;


