import React from 'react';
import {Services2Section,SectionWrapper,CustomRow,LeftLayout,HeadingLayout,ServicesBtn,
    ImageHolder
} from './services2.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const Services2 = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                servicesPage1Json{
                    Services2{
                       Heading
                       SubHeading
                       Description1
                       Img{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                       }
                    }
                }
            }
    `);
    const Services2Data = JSONData.servicesPage1Json.Services2;

    return (
        <Services2Section id="Services2Section">
            <SectionWrapper>
                <Container>
                    <CustomRow>
                        <Col lg={6}>
                            <LeftLayout>
                                <HeadingLayout>
                                    <h3>
                                        {Services2Data.Heading}
                                    </h3>
                                    <h6>
                                        {Services2Data.SubHeading}
                                    </h6>
                                </HeadingLayout>
                                <p>
                                {Services2Data.Description1}
                                </p>
                                <ServicesBtn href="/">Learn More</ServicesBtn>
                            </LeftLayout>
                        </Col>
                        <Col lg={6}>
                            <ImageHolder>
                                <GatsImg
                                    fluid={Services2Data.Img.childImageSharp.fluid}
                                    alt=""
                                />
                            </ImageHolder>
                        </Col>
                    </CustomRow>
                </Container>
            </SectionWrapper>
        </Services2Section>
    );
}

export default Services2;
