import styled from 'styled-components';
import {Commonbtn} from '../../../components/Common/common.style';
import BackgroundImg from '../../../assets/services-1-images/banner-2.jpg'
import {device} from '../../../components/Common/device';

export const CTABannerSection = styled.section`
    padding:100px 0px;
    background-image:url(${BackgroundImg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const TextBtnLayout = styled.div`
    text-align:center;

    h3{
        color:#fff;
        text-align:center;
        margin-bottom:30px;
    }
`;


export const BannerBtn = styled(Commonbtn)`
    box-shadow:none;
`;
