import React from 'react';
import {CTABannerSection,TextBtnLayout,BannerBtn} from './ctabanner.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const CTABannerPage  = (props) => {

    const JSONData  = useStaticQuery(graphql`
    query {
        servicesPage1Json{
            CTABanner{
                BannerText
                BannerBtn
            }
        }
    }
  `);
  const CTABannerData = JSONData.servicesPage1Json.CTABanner;

    return (
        <CTABannerSection BackgroundImg={props.BackgroundImg}>
            <Container>
                <Row>
                    <Col md={12}>
                        <TextBtnLayout>
                            <h3>
                            {CTABannerData.BannerText}
                            </h3>
                            <BannerBtn>
                            {CTABannerData.BannerBtn}
                            </BannerBtn>
                        </TextBtnLayout>
                   </Col>
                </Row>
            </Container>
        </CTABannerSection>
    );
}
export default CTABannerPage;
