import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {Commonbtn} from '../../../components/Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Services2Section = styled.section`
    padding:100px 0px;
    background:#191919;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const SectionWrapper = styled.div`

`;

export const CustomRow = styled(Row)`
    align-items:center;
    flex-direction:row-reverse;
`;

export const LeftLayout = styled.div`
    padding-right:10px;

    @media ${device.laptop} {
        padding-right:0px;
    }

    p{
        color:#fff;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:20px;

    h3{
        color:#fff;
        margin-bottom: 20px;
    }
    h6{
        color:#fff;
    }
`;

export const ServicesBtn = styled(Commonbtn)`

`;



export const ImageHolder = styled.div`
    padding-left:10px;

    @media ${device.laptop} {
        padding-left:0px;
        margin-top:40px;
    }

    @media ${device.mobileXL}{
        margin-top:30px;
    }
`;
