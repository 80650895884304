import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const ClientSection = styled.section`
    padding:0px 0px 100px;

    @media ${device.tablet} {
        padding-bottom:80px;
    }

    hr{
        margin:0px;
        margin-bottom:100px;

        @media ${device.tablet} {
            margin-bottom:80px;
        }
    }
`;

export const ClientLogoLayout = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;
`;

export const ClientLogoHolder = styled.div`
    width:20%;
    text-align:center;

    @media ${device.laptop} {
        width:25%;
        margin-bottom:30px;
    }

    @media ${device.tablet} {
        width:50%;
        margin-bottom:30px;
    }

    :last-child{
        margin-bottom:0px;
    }
`;

export const ClientLogo = styled.div`
    width:120px;
    margin:0px auto;
`;
