import React from 'react';
import {Services3Section,SectionWrapper,CustomRow,LeftLayout,ServicesBtn,
    ImageHolder
} from './services3.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const Services3 = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                servicesPage1Json{
                    Services3{
                       Heading
                       SubHeading
                       Description1
                       Img{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                       }
                    }
                }
            }
    `);
    const Services3Data = JSONData.servicesPage1Json.Services3;

    return (
        <Services3Section id="Services3Section">
            <SectionWrapper>
                <Container>
                    <CustomRow>
                        <Col lg={6}>
                            <LeftLayout>
                                <h3>
                                    {Services3Data.Heading}
                                </h3>
                                <p>
                                {Services3Data.Description1}
                                </p>
                                <ServicesBtn href="/">Learn More</ServicesBtn>
                            </LeftLayout>
                        </Col>
                        <Col lg={6}>
                            <ImageHolder>
                                <GatsImg
                                    fluid={Services3Data.Img.childImageSharp.fluid}
                                    alt=""
                                />
                            </ImageHolder>
                        </Col>
                    </CustomRow>
                </Container>
            </SectionWrapper>
        </Services3Section>
    );
}

export default Services3;
