import React from 'react';
import {OurSolutionsSection,HeadingLayout,MainHeading,
    OurSolutionsSingle
} from './oursolutions.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const OurSolutions = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                servicesPage1Json{
                    OurSolutions{
                        Heading
                        OurSolutionsCard{
                            OurSolutionsImg
                            OurSolutionsHeading
                            OurSolutionsDesc
                        }
                    }
                }
            }
    `);
    const OurSolutionsData = JSONData.servicesPage1Json.OurSolutions;
        return (
        <OurSolutionsSection id="OurSolutionsSection">
            <Container>
                <HeadingLayout>
                    <MainHeading>
                    {OurSolutionsData.Heading}
                    </MainHeading>
                </HeadingLayout>
                <Row>
                {
                    OurSolutionsData.OurSolutionsCard.map((item,idx) => {
                    return <Col lg={4} md={6}>
                        <OurSolutionsSingle>
                            <img
                                src={item.OurSolutionsImg}
                                alt=""
                            />
                            <h5>
                            {item.OurSolutionsHeading}
                            </h5>
                            <p>
                            {item.OurSolutionsDesc}
                            </p>
                        </OurSolutionsSingle>
                    </Col>
                    })
                }
                </Row>
            </Container>
        </OurSolutionsSection>
    );
}

export default OurSolutions;
