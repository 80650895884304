import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SectionHeading} from '../../../components/Common/common.style';

export const OurSolutionsSection = styled.section`
    background-repeat:no-repeat;
    background-size:cover;
    padding:100px 0px 70px;

    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;


export const HeadingLayout = styled.div`
    margin-bottom:75px;
    text-align:center;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const MainHeading = styled(SectionHeading)`
    text-align: center;

`;

export const OurSolutionsSingle = styled.div`
    padding: 30px 20px;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-bottom: 30px;

    img{
        width:auto;
        height:50px;
        margin-bottom:20px;
    }
    h5{
        line-height:1;

        @media ${device.tablet}{
            line-height:1;
        }
    }

    p{
        margin:0px;
    }

`;
