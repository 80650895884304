import React from 'react';
import {Container} from 'react-bootstrap';
import {
    FeaturesSection,
    FeaturesLogoLayout,
    FeaturesLogoHolder
} from './features.style';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        servicesPage1Json{
            Features{
                FeaturesHeading
                FeaturesLogoDetails{
                    FeaturesLogo
                    FeaturesText
                }
                FeaturesImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
  `);
  const FeaturesData = JSONData.servicesPage1Json.Features;

    return(
        <FeaturesSection>
            <Container>
                <h2>{FeaturesData.FeaturesHeading}</h2>
                <FeaturesLogoLayout>
                    {
                        FeaturesData.FeaturesLogoDetails.map(item=>{
                            return <FeaturesLogoHolder>
                                <img alt="" src={item.FeaturesLogo} />
                                <h5>{item.FeaturesText}</h5>
                            </FeaturesLogoHolder>
                        })
                    }
                </FeaturesLogoLayout>
                <span>
                <GatsImg
                        fluid={FeaturesData.FeaturesImage.childImageSharp.fluid}
                        alt=""
                    />
                </span>
            </Container>
        </FeaturesSection>
    );
}

export default Features;
