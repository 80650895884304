import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const FeaturesSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 5px;
    }

    h2{
        text-align:center;
        margin-bottom: 75px;

        @media ${device.tablet} {
            margin-bottom: 60px;
        }
    }

    span{
        height: auto;
        text-align: center;
        margin: auto;
        margin-top: 30px;
        max-width:800px;
        display: block;
    }
`;

export const FeaturesLogoLayout = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;

`;

export const FeaturesLogoHolder = styled.div`
    width:18%;
    text-align:center;
    padding: 15px 0px;

    @media ${device.laptop} {
        width:50%;
        margin-bottom:10px;

        :last-child{
            width:100%;
        }
    }

    :last-child{
        margin-bottom:0px;
    }

    img{
        height: 50px;
        margin-bottom: 20px;
    }
`;
