import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Banner from "../components/CommonBanner"
import BackgroundImg from '../assets/services-1-images/banner.jpg';
import OurSolutions from "../containers/ServicesPage1/OurSolutions"
import Services2 from "../containers/ServicesPage1/Services2"
import Services3 from "../containers/ServicesPage1/Services3"
import Features from "../containers/ServicesPage1/Features"
import CTABanner from "../containers/ServicesPage1/CTABanner"
import Testimonials from "../containers/ServicesPage1/Testimonials"
import Clients from "../containers/ServicesPage1/Clients"
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Layout from "../components/layout";
import CTABackgroundImg from '../assets/services-1-images/banner-2.jpg';

const ServicesPage1 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS/>
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="Our Services"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Our Services"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/services-1"
        />
        <OurSolutions />
        <Services2 />
        <Services3 />
        <Features />
        <CTABanner 
            BackgroundImg={CTABackgroundImg}
        />
        <Testimonials />
        <Clients />
        <Footer />
    </Layout>
)

export default ServicesPage1;